import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import request, { baseURL } from './request/request'
//  import '../node_modules/swiper/swiper.min.css';

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

Vue.prototype.$request = request
Vue.prototype.$baseURL = baseURL

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */



  if (to.meta.title) {
  document.title = to.meta.title
  }
  if(to.meta.content){
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
 
 
  meta.content = to.meta.content;
  head[0].appendChild(meta)
  }
  next()
  })