<template>

    <div id="footer">

	<div class="w">
		<div class="content">


			<div class="about">
				<div class="aboutContent">
				<div class="title">
					<img src="../assets/img/Tib2T8siZK.png" alt="">
				</div>
				<ul class="linkLits">
					<li class="linkItem1" ><a href="mailto:info@hlhproto.com">info@hlhcnc.com</a></li>
				<li class="linkItem1"><a href="tel:+86-731-8223-4949">+86-27-59089998</a></li>
                    <li class="linkItem1">ext 8020</li>
					<li class="linkItem">Room 507，Floor 5 ，Building E，Donghe Center，Wuhan Economic and Technological Development Zone， Wuhan, China</li>
				</ul>
				</div>
			</div>
        <div class="contentRi">
			<div class="Services">
				<p class="title">CNC MACHINING</p>
				<ul class="linkLits">
					<li class="linkItem">
							<router-link   :style="{color:(currentPage=='cnc-plastic'?colorbg:'')}" to="/cnc-plastic">CNC Plastic Machining</router-link>
					</li>
					<li class="linkItem">
	<router-link :style="{color:(currentPage=='cnc-metal'?colorbg:'')}" to="/cnc-metal">CNC Metal Machining</router-link>
 
					</li>
					<li class="linkItem">
 <router-link :style="{color:(currentPage=='cnc-machining-plastic'?colorbg:'')}" to="/cnc-machining-plastic">CNC Plastic Material</router-link>
					</li>
					<li class="linkItem">
	<router-link :style="{color:(currentPage=='cnc-machining-metal'?colorbg:'')}" to="/cnc-machining-metal">CNC Metal Material</router-link>
					</li>
				</ul>
			</div>
			<div class="Resovrces">
				<p class="title">SURFACE FINISH</p>
				<ul class="linkLits">
	<!-- <li class="linkItem">
						<router-link :style="{color:(currentPage=='surface-finish'?colorbg:'')}" to="/surface-finish">Surface Finish</router-link>
					</li> -->
						<li class="linkItem">
						<router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Polishing</router-link>
					</li>
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Anodizing</router-link>
					</li>
						<li class="linkItem">
					<router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Sandblasting</router-link>
					</li> 
					<li class="linkItem">
 <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Painting</router-link>	 
					</li>
						<li class="linkItem">
              <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Powder coating</router-link>	 
					</li>	
					<li class="linkItem">
             <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Chromate conversion coating</router-link>	 
					</li>
					<li class="linkItem">
              <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Passivation</router-link>	 
 
					</li>
					<li class="linkItem">
            <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Zinc plating</router-link>	 
					</li>
					<li class="linkItem">
            <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Nickel plating</router-link>	 
					</li>
					<li class="linkItem">
             <router-link :style="{color:(currentPage=='surface-finish1'?colorbg:'')}" to="/surface-finish">Electropolishing</router-link>	 
					</li>

				</ul>
			</div>
			<div class="Contact">
				<p class="title">COMPANY</p>
				<ul class="linkLits"> 
					<li class="linkItem">
						<a :style="{color:(currentPage=='about-us' ?colorbg:''), cursor: 'pointer'}" @click="jump3('/about-us','')"  >About Us</a>
					</li>
          <li class="linkItem">
						<a :style="{color:(currentPage=='contact-us' ?colorbg:''), cursor: 'pointer'}" @click="jump3('/contact-us','')"  >Contact Us</a>
					</li>
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='quality-assurance'?colorbg:''), cursor: 'pointer'}"  to="/quality-assurance">Quality Assurance</router-link>
					</li>
   <li class="linkItem">
					<p class="blog" :style="{color:(currentPage=='Blog'?colorbg:'') , cursor: 'pointer'} "  @click="jump4(Blog)">Blog </p>
					</li>
				</ul>
			</div>
			<div class="Quote">
				<p class="title">INDUSTRIES</p>
				<ul class="linkLits">
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='aerospace-uav'?colorbg:'')}" to="/aerospace-uav">Aerospace & UAV</router-link>
					</li>
					<li class="linkItem">
							<router-link :style="{color:(currentPage=='automotive'?colorbg:'')}" to="/automotive">Automotive</router-link>
					</li>
          <li class="linkItem">
							<router-link :style="{color:(currentPage=='robotics-automation'?colorbg:'')}" to="/robotics-automation">Robotics & Automation</router-link>
					</li>
					<li class="linkItem">
							<router-link :style="{color:(currentPage=='communications'?colorbg:'')}" to="/communications">Communications</router-link>
					</li>
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='consumer-products'?colorbg:'')}" to="/consumer-products">Consumer Products</router-link>
					</li>
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='industrial'?colorbg:'')}" to="/industrial">Industrial</router-link>
					</li>
					<li class="linkItem">
						<router-link :style="{color:(currentPage=='medical'?colorbg:'')}" to="/medical">Medical</router-link>
					</li>
					<li class="linkItem">
							<router-link :style="{color:(currentPage=='product-development'?colorbg:'')}" to="/product-development">Product Development</router-link>
					</li>
				</ul>
			</div>
		</div>
		</div>
	</div>
    <div class="shareFoot">
            <div class="w">
                <div class="Copyright">Copyright 2022 HLH Mold Technology Co., Ltd</div>
                <div class="share">
				<div class="shareItme"> <img src="../assets/img/instagram.png" alt="">  </div>	
				<div class="shareItme"> <img src="../assets/img/tt.png" alt="">  </div>	
				<div class="shareItme"> <img src="../assets/img/facebook.png" alt=""> </div>	
                </div>
            </div>
        </div>
</div>
</template>
<script>
export default {
  name: "myHead",
  data() { 
    return {
      colorbg: "#da251d",
      currentPage: "",
      lb: "",
      Blog:'https://blog.hlhcnc.com/',
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
 
      this.currentPage = to.name;
    },
   
  },
  methods: {
    jump3(path, lb) {
      this.$router.push({ path: path }).catch(err => err);
      this.$store.dispatch("setLb", lb);
    },
    jump4(path){
     window.open(path)
    },
    isTou() {
      this.$store.dispatch("setDialogVisible", true);
    }
  }
};
</script>

<style scoped lang="scss">
.linkItema :hover{
  color: #da251d

}
#footer {
  width: 100%;
  background-color: #191919;
   .w{
	margin: auto 

  }

.shareItme{
	width: 40px;
	height: 40px;
	// background: red;
	margin-right:20px;
	img{
		width: 100%;
		height: 100%;
	}
}
  .content {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
	margin: auto;
    .contentRi {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    > div {
      font-size: 16px;
      color: #c2c2c2;
      &:nth-child(1) {
        padding-left: 0;
      }
      &:nth-child(2) {
        padding-left: 0;
      }
      .title {
        color: #fff;
      }
      .linkLits {
        .linkItem {
          line-height: 30px;
          display: block;
          a {
            color: #c2c2c2;
            width: 214px;
            word-wrap: break-word;
            display: block;
            &:hover {
              color: #da251d;
            }
          }
        }
        a:hover{
          color: #da251d

        }
        .linkItem1 {
          line-height: 30px;
          cursor: pointer;
          a {
            color: #c2c2c2;
            &:hover {
              color: #da251d;
            }
          }
        }
      }
      img {
        width: 100%;
        max-width: 300px;
        
        margin-bottom: 20px;
      }
    }
     .blog:hover {
 color: #da251d
  }
    .about {
      .linkLits {
        .linkItem {
          line-height: 24px;
          margin-bottom: 25px;
		margin-top: 20px;
        }
      }
    }
  }
  .shareFoot {
    background-color: #171717;
    width: 100%;
    z-index: 9;

    color: #c2c2c2;
    .w {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .Copyright {
      font-size: 14px;
    }
    .share {
      float: right;
      display: flex;

      span {
        margin-left: 20px;
      }
      a {
        margin-left: 20px;
        color: #c2c2c2;
        &:hover {
          color: #da251d;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .w {
    min-width: 330px;
    width: 100%;
  }
 
  .shareFoot {
    width: 100%;
    margin: auto;
    .w {
      width: 90%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .shareItme {
        margin:0 20px ;
      }
      .Copyright {
        width: 100%;
        text-align: center;
        line-height: 64px;
      }
      .share {
        display: flex;
        justify-content: center;
        margin: auto;
        flex-wrap: wrap;
      }
    }
  }
  .contentRi {
    width: 90%;
    margin: auto;
    .Services {
      margin-bottom: 26px;
      width: 180px;
      font-size: 14px;
      .title {
        margin-bottom: 25px;
        font-size: 18px;
      }
    }
    .Resovrces {
      margin-bottom: 26px;
      width: 176px;
      font-size: 14px;
      .title {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
    .Contact {
      margin-bottom: 26px;
      width: 190px;
      font-size: 14px;
      .title {
        margin-bottom: 25px;
        font-size: 18px;
      }
    }
    .Quote {
      margin-bottom: 26px;
      width: 170px;
      font-size: 14px;
      .title {
        margin-bottom: 25px;
        font-size: 18px;
       
      }
    }
  }
  .content {
    // padding: 80px 0 0 0;
    transition: 0.25s;
  }
  .about {
    margin: auto;
    width: 100%;
    border-top: 1px solid #666;
    .title {
      text-align: center;
      margin-bottom: 0px;
    }
    .linkItem1 {
      margin: auto;
      text-align: center;
    }
    .linkItem {
      margin: 21px 0;
      text-align: center;
    }
    .aboutContent {
      width: 400px;
      margin: auto;
      padding-top: 40px;
    }
  }
}
@media screen and (min-width: 920px) and (max-width: 1350px) {
  .Copyright {
    line-height: 64px;
  }
  .shareFoot {
    width: 100%;
    margin: auto;
    .w {
      width: 90%;
      margin: auto;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .Copyright {
        width: 100%;
        text-align: center;
      }
      .share {
        display: flex;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;

        justify-content: center;
      }
    }
  }
  .w {
    min-width: 330px;
    width: 100%;
  }
  .contentRi {
    width: 90%;
    margin: auto;
    .Services {
      margin-bottom: 26px;
      width: 190px;
      font-size: 14px;
      .title {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
    .Resovrces {
      margin-bottom: 26px;
      width: 190px;
      font-size: 14px;
      .title {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
    .Contact {
      margin-bottom: 26px;
      width: 190px;
      font-size: 14px;
      .title {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
    .Quote {
      margin-bottom: 26px;
      width: 190px;
      font-size: 14px;
      .title {
        margin-bottom: 25px;
        font-size: 18px;
      }
    }
  }
  .content {
    padding-bottom:50px;
    transition: 0.25s;
  }
  .about {
    margin: auto;
    width: 100%;

    border-top: 1px solid #666;
    .title {
      text-align: center;
      margin-bottom: 0px;
    }
    .linkItem1 {
      margin: auto;
      text-align: center;
    }
    .linkItem {
      margin: 21px 0;
      text-align: center;
    }
    .aboutContent {
      width: 400px;
      margin: auto;
      padding-top: 40px;
    }
  }
}
@media screen and (min-width: 1350px) {
  .w {
    width: 1200px;
  }
  .about { 
    margin-right: 68px;
    width: 204px;
    .title {
      text-align: left;
      margin-bottom: 25px;
    }
  }
  .content {
    padding-bottom:50px;
    transition: 0.25s;
  }
  .Services {
    width: 252px;
    .title {
      margin-bottom: 25px;
    }
  }
  .Resovrces {
    width: 212px;
    .title {
      margin-bottom: 25px;
    }
  }
  .Quote {
    width: 190px;
    .title {
      margin-bottom: 25px;
    }
  }
  .Contact {
    width: 270px;
    .title {
      margin-bottom: 25px;
    }
  }
  .Copyright,
  .LinkedIn {
    line-height: 76px;
  }
}
</style>