<template>
<div class='page'>
    <div class="content"> 
        <div class="img"><img  src="../assets/img/requiredimg.png" alt=""></div>
         <div class="requiredContent">
             <div class="title">Required</div>
             <div class="itemModel">
                <div class="left">
                    <div class="item">
                         <input v-model="firstName" type="text" placeholder="Name*">
                    </div>
                      <div class="item">
                         <input v-model="email" type="text" placeholder="Email*">
                    </div>
                      <!-- <div class="item">
                         <input v-model="phone" type="text" placeholder="Phone*">
                    </div> -->

                </div>
                <div class="rig">
                    <div class="item2">
                    <uploadoss class="selectFile" v-on:getossdata="showossdataHead" title="Browse" v-on:getossurl="showossurlHead" url="projectreview/upload"></uploadoss>
                              <span class="fileName">{{fileName}}</span>
                    </div>
                    <div class="item3">
                           <input v-model="phone" type="text" placeholder="Phone*">
                           <!-- <div class="textareabg"> 
                                       <textarea  v-model="message" placeholder="Message" ></textarea>
                           </div> -->
                    </div>
                     <!-- <div class="item3">
                         <el-button :loading="isbool"  @click="submit"  >Send Message Or Free Project Review</el-button>

                    </div> -->

                </div>
                
                
             </div>
             <div class="mes">
                   <div class="textareabg"> 
                                       <textarea  v-model="message" placeholder="Message" ></textarea>
                           </div>
             </div>
             <div class="">
                     <el-button :loading="isbool"  @click="submit"  >Send Message Or Free Project Review</el-button>
             </div>
            
            
         </div>
    </div>
</div>
</template>
<script>
import uploadoss from '../components/uploadoss1.vue'
export default {
data() {
return {
    firstName:'',
    message:'',
    phone:'',
    email:'',
    file:'',



    fileName:'No file selected.',
    isbool:false,
}
},
methods: {
           showossdataHead(data) {
         
			this.fileName = data[0].name
        },
		showossurlHead(fileArr) {
         
			this.file = fileArr[0].path
		},
        submit(){
            this.isbool=true
			if(this.firstName == '') {
				this.$message({
					message: 'Enter your name',
					type: 'warning'
				});
                  this.isbool=false
				return false
			}
			if(this.email == '') {
				this.$message({
					message: 'Enter your EMail',
					type: 'warning'
				});
                  this.isbool=false
				return false
			}
			if(this.phone == '') {
				this.$message({
					message: 'Enter your phone number',
					type: 'warning'
				});
				return false
			}
			this.$request.post('/projectreview/insert',{
				firstName:this.firstName,
				lastName:this.lastName,
				email:this.email,
                message:this.message,
				phone:this.phone,
				fileUpload:this.file,
			}).then((res)=>{
				if(res.code == 0) {
                    this.isbool=false
					this.$message({
						message: 'submit successfully',
						type: 'success'
					});
					this.firstName = '',
					this.email = '',
					this.phone = '',
					this.file = '',
                    this.message='',
					this.isRobot = false
                    
				}else{
                      this.isbool=false
                }
			}) 
		}
},
mounted() {},
created() {},
props:{},
watch:{ },
components:{
    uploadoss
}
}
</script>
<style scoped>
.title{
    margin-bottom:10px ;
    color: #da251d;
    font-weight: bold
}
.left{
 width: 300px;
}
.mes{
    padding-bottom:20px 
}
  .selectFile {
                                display: inline-block;
                                width: 68px;
                                height: 22px;
                                background-color: #da251d;
                                color: white;
                              
                                text-align: center;
                                font-size: 12px;
                                margin-right: 15px;
                                border-radius: 3px;
                                margin-top: 15px;
                            }
 .textareabg{
                        width: 100%;
                         border: 1px solid #E5E5E5;  
                         background: white;

                         }
                              textarea{
                   
                    width: 100%;
                    padding: 10px 15px;
                    height: 40px;
                
                    border: none;
                    font-size: 13px;
                     font-family: 'WorkSans-Regular';
                }
                textarea::-webkit-input-placeholder {
                    /* WebKit browsers */
                    /* placeholder颜色  */
                    color: #757575;
                    /* placeholder字号  */
                    font-size: 13px;
                 
                  font-family: 'WorkSans-Regular';

                  }
                  textarea:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #757575;
                    font-size: 13px;
                  }
                  textarea::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #757575;
                    font-size: 13px;
                  }
                  textarea::-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    color: #757575;
                    font-size: 13px;
                  }

.fileName{
    color: #999999;
    font-size: 13px
}
.rig{
    /* width: 440px; */
    
    margin-left: 60px;
}
.item{
    margin-bottom:25px; 
   width: 100%;
}
.item3{
      width: 100%;
    height: 45px;
       margin-bottom:25px; 
    background: none
}
.item2{
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding-left:15px;
       margin-bottom:25px; 
    background: white
}
button{
    height: 100%;
    background: #da251d;
     border:none;
    color: white
}
button:hover{
      background: #da251d;

    color: white
}
.itemModel{
    display: flex;
    width: 100%;
    justify-content: space-between
    
}
  input {
                            width: 100%;
                            height: 45px;
                            border: 1px solid #E5E5E5;
                            padding-left: 15px;
                            font-size: 14px;
                          
                            
                        }
.page{
    width: 100%;
    background: #191919;
    padding: 50px 0;
    
}
.content{
    width: 1200px;
 
    margin: auto;
    justify-content: space-between
 
}
.img{
    min-width: 330px;
    width: 360px;
    max-width: 460px;
    /* height: 234px; */
    background: none;
    margin: auto
}
/* .requiredContent{
    width: 1060px;

 
    margin-left:50px;
    
} */
img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 760px) {
 .content{
        width: 90%;
        min-width: 330px
    }
    .itemModel{
        display: block
    }
    .left{
    width:100% ;
}
   .img{
        width: 100%;
    }
.item3{
    width: 100%;
}
.requiredContent{
    margin-top:30px 
}
     .rig{
        width: 100%;
        margin-left: 0px
    }
    button{
        width: 100%;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
 .content{
        width: 90%;
            display: block
    }
    .img{
        width: 100%;
    }
        .left{
        width: 50%;

    }
    .requiredContent{
    width: 100%; 
    margin-top: 30px
}
.rig{
    width:440px ;
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
   .content{
        width: 90%;
            display: flex
    }
    .left{
        width: 50%;

    }
 .rig{
        width: 57%;
        margin-left: 25px
    }
    .requiredContent{
    width: 1060px;

 
    margin-left:50px;
    
}
}
@media screen and (min-width: 1350px) {
    .content{
        width: 1200px; 
           display: flex
    }
    .left{
        width: 300px;
    }
    
    .rig{
        width: 440px;
    }
    .requiredContent{
    width: 1060px;

 
    margin-left:50px;
    
}
}

</style>