import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect:'/',
     
  },
  //home   ok
  {
    path: '/',
    name: 'Home',
    component: () => import('../views//home/home.vue'),
    meta:{
      title:"Home",
      content:{
        name:'Home',
        keywords:'Home',
        description:"Home,Home",
      },
    }
  },


  //cnc plastic      ok
 
  {
    path: '/cnc-plastic', //地址
    name:"cnc-plastic",
    component: () => import('../views/cncPlastic/cncPlastic.vue'),
    meta:{
      title:"CNC Plastic Machining",          //页面标题
      content:{
        name:'CNC Plastic Machining',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
   
  {
    path: '/cnc-machining-plastic', //地址
    name:"cnc-machining-plastic",
    component: () => import('../views/cncMachingPlastic/cncMachingPlastic.vue'),
    meta:{
      title:"CNC Plastic Material",          //页面标题
      content:{
        name:'CNC Plastic Material',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  //cnc metal
  {
    path: '/cnc-metal', //地址
    name:"cnc-metal",
    component: () => import('../views/cncMetal/cncMetal.vue'),
    meta:{
      title:"CNC Metal Machining",          //页面标题
      content:{
        name:'CNC Metal Machining',//当前标题名称 
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/cnc-machining-metal', //地址
    name:"cnc-machining-metal",
    component: () => import('../views/cncMachingMetal/cncMachingMetal.vue'),
    meta:{
      title:"CNC Metal Material",          //页面标题
      content:{
        name:'CNC Metal Material',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  //surface finish
  {
    path: '/surface-finish', //地址
    name:"surface-finish",
    component: () => import('../views/surfaceFinish/surfaceFinish.vue'),
    meta:{
      title:"Surface Finish",          //页面标题
      content:{
        name:'Surface Finish',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  //industries
  {
    path: '/aerospace-uav', //地址
    name:"aerospace-uav",
    component: () => import('../views/aerospaceUAV/aerospaceUAV.vue'),
    meta:{
      title:"Aerospace & UAV",          //页面标题
      content:{
        name:'Aerospace & UAV',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/automotive', //地址
    name:"automotive",
    component: () => import('../views/automotive/automotive.vue'),
    meta:{
      title:"Automotive",          //页面标题
      content:{
        name:'Automotive',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/communications', //地址
    name:"communications",
    component: () => import('../views/communications/communications.vue'),
    meta:{
      title:"Communications",          //页面标题
      content:{
        name:'Communications',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/consumer-products', //地址
    name:"consumer-products",
    component: () => import('../views/consumerProducts/consumerProducts.vue'),
    meta:{
      title:"Consumer Products",          //页面标题
      content:{
        name:'Consumer Products',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/industrial', //地址
    name:"industrial",
    component: () => import('../views/industrial/industrial.vue'),
    meta:{
      title:"Industrial",          //页面标题
      content:{
        name:'Industrial',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/medical', //地址
    name:"medical",
    component: () => import('../views/medical/medical.vue'),
    meta:{
      title:"Medical",          //页面标题
      content:{
        name:'Medical',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/product-development', //地址
    name:"product-development",
    component: () => import('../views/productDevelopment/productDevelopment.vue'),
    meta:{
      title:"Product Development",          //页面标题
      content:{
        name:'Product Development',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 
  {
    path: '/robotics-automation', //地址
    name:"robotics-automation",
    component: () => import('../views/roboticsAutomation/roboticsAutomation.vue'),
    meta:{
      title:"Robotics & Automation",          //页面标题
      content:{
        name:'Robotics & Automation',//当前标题名称
        keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
        description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
      },
    }
  }, 

    //company
    {
      path: '/about-us', //地址
      name:"about-us",
      component: () => import('../views/aboutUs/aboutUs.vue'),
      meta:{
        title:"About Us",          //页面标题
        content:{
          name:'About Us',//当前标题名称
          keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
          description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
        },
      }
    }, 
    {
      path: '/quality-assurance', //地址
      name:"quality-assurance",
      component: () => import('../views/qualityAssurance/qualityAssurance.vue'),
      meta:{
        title:"Quality Assurance",          //页面标题
        content:{
          name:'Quality Assurance',//当前标题名称
          keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
          description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
        },
      }
    }, 
    {
      path: '/contact-us', //地址
      name:"contact-us",
      component: () => import('../views/ContactUs/ContactUs.vue'),
      meta:{
        title:"Contact Us",          //页面标题
        content:{
          name:'Contact Us',//当前标题名称
          keywords:'injection molding prototyping,injection molded parts,rapid injection molding,rapid tooling',
          description:"Get to know about Injection Molding Service in detail. HLH PROTO LTD. is absolutely your best partner you can rely on. For more information visit our website today! ",
        },
      }
    }, 
  

  
]

const router = new VueRouter({
  routes,
  mode:'history'
})

export default router
