<template>
  <div id="app">
   
   <div class="logoBg">
      <div class="logo">	
        <div class="logoleft" @click="jump('/')"> <img  class="imglogo" src="./assets/img/Tib2T8siZK.png" alt=""></div>
       
        </div>
   </div>
  
    <router-view/>
    <required></required>
    <myfooter></myfooter>
    
  </div>
</template>
<script>

import required from './components/required.vue'
import myfooter from './components/myFooter.vue'
export default {
data() {
return {}
},
methods: {
  jump(path){
    this.$router.push({ path: path }).catch(err => err);

  }
},
mounted() {},
created() {},
props:{},
watch:{ },
components:{
myfooter ,required
}
}
</script>
<style   lang="scss" >
html{
  padding: 0;
  margin: 0px
}
#app{
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
 
}
.imglogo{
  width: 100%;
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
.logo{

width: 1200px;
 margin: auto
   
 
}
.logoBg{

   position:absolute;
  z-index: 11;
  width: 100%;
  height: auto;
    top: 0px;
  left: 0px;
  margin: auto;
  padding-top:41px 
  // background: red;
}
.logoleft{
  // width: 314px;
  width: 251px;
  cursor: pointer;
}


li {
    list-style: none;
}
input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    caret-color:#cacacc;
    outline: none;
}
html, body {
    width: 100%;
    height: 100%;
}
body {
    // #app{
        font-family: 'worksans';
     
        color: #333;
      
    // }
}



 @media screen and (max-width: 760px) {
  .logo{
   display: none
  }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .logo{
   display: none
  }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .logo{
  width: 90%;
  }

}
@media screen and (min-width: 1350px) {
    
}



</style>